"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Colors_1 = require("@enums/Colors");
var mui_1 = require("tss-react/mui");
var svgShapes = {
    outline: "none",
    backgroundColor: "transparent !important",
};
var wrapSquare = {
    width: "calc(100% / 2) !important",
};
var wrapInscribedRectInCircle = {
    width: "calc(100% / 2 * sqrt(2)) !important",
};
var useStyles = (0, mui_1.makeStyles)()(function (theme) { return ({
    attr: {
        fontSize: 6,
        textAlign: "center",
        margin: 5,
    },
    nodePopper: {
        backgroundColor: Colors_1.otherGenericColors.panel,
        padding: 10,
    },
    nodePopperTop: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    nodePopperTopIcons: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    labelContainer: {
        width: "100%",
        height: "100%",
        overflow: "visible",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textOverflow: "ellipsis",
    },
    textNode: {
        width: "100%",
        height: "100%",
    },
    header: {
        textAlign: "center",
        width: "100%",
        lineHeight: 1.2,
    },
    svgShapeTextContainer: {
        position: "absolute",
    },
    node: {
        display: "flex",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        alignSelf: "center",
    },
    roundRectangle: {
        borderRadius: theme.rounded.small,
        "&:before": {
            borderRadius: theme.rounded.small,
        },
        h6: {
            padding: "0 1pt",
        },
    },
    rectangle: {},
    circle: {
        borderRadius: "50%",
        "&:before": {
            borderRadius: "50%",
        },
        h6: __assign({}, wrapInscribedRectInCircle),
    },
    diamond: __assign(__assign({}, svgShapes), { h6: __assign({}, wrapSquare) }),
    triangle: __assign(__assign({}, svgShapes), { h6: __assign({}, wrapSquare) }),
    parallelogram: __assign(__assign({}, svgShapes), { h6: __assign({}, wrapSquare) }),
    person: __assign(__assign({}, svgShapes), { h6: __assign({}, wrapSquare) }),
    star: __assign(__assign({}, svgShapes), { h6: __assign({}, wrapSquare) }),
    arrow: __assign(__assign({}, svgShapes), { h6: {
            width: "calc(100% - 10px) !important",
            alignSelf: "flex-start",
            paddingLeft: "calc(1px + 1pt)",
        } }),
    document: __assign(__assign({}, svgShapes), { h6: {
            padding: "0 1pt",
        } }),
    pentagon: __assign({}, svgShapes),
    sqcHybrid: __assign(__assign({}, svgShapes), { h6: {
            // not perfect
            width: "calc(100% - 12pt) !important",
        } }),
    trapezoid: __assign(__assign({}, svgShapes), { h6: {
            // not perfect
            width: "calc(100% - 12px) !important",
        } }),
    soround: {
        position: "absolute",
        zIndex: -2000,
        cursor: "default",
    },
    infoIcon: {
        fontSize: 8,
        color: "white",
    },
    infoButton: {
        borderRadius: 5,
        backgroundColor: "black",
        padding: 0,
        position: "absolute",
        top: 3,
        left: 3,
    },
    errorIcon: {
        fontSize: 10,
        position: "absolute",
        top: 2,
        left: 2,
        cursor: "auto",
        zIndex: 110,
    },
    label_rectangle: {},
    label_circle: {},
    label_triangle: {},
    exitLevelPWC: {
        position: "absolute",
        top: -30,
        width: 250,
        pointerEvents: "none",
        fontSize: 12,
        borderBottom: "1px dashed black",
    },
}); });
exports.default = useStyles;
