"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var document_1 = require("@customTypes/reducers/document");
var documentConstants_1 = require("./documentConstants");
var folderConstants_1 = require("@redux/constants/folderConstants");
var objectIterationMethods_1 = require("@utils/objectIterationMethods");
var lawsuits_1 = require("@customTypes/reducers/lawsuits");
var initialDocument = {
    id: 0,
    title: "",
    description: "",
    link: undefined,
    signedUrl: undefined,
    file_type: "",
    organization_id: 0,
    group_id: null,
    lawsuit_id: null,
    created_at: "",
    updated_at: "",
    groupOrLawsuit: lawsuits_1.INITIALLAWSUIT,
    tags: [],
    notes: [],
    section: undefined,
    appendix: null,
    isDraft: false,
};
var initialValidation = {
    datesFoundOnDocument: null,
    currentDocumentForValidation: null,
    documentsOnHold: [],
};
var initialState = {
    documents: [],
    loadings: document_1.initialDocumentLoadings,
    documentOptions: [],
    document: initialDocument,
    documentsDescendants: [],
    deletingFolder: 0,
    bundleSectionOptions: [],
    bundleSections: [],
    bundleSectionsAvailable: [],
    validation: initialValidation,
    documentToken: "",
    uploadProgress: [],
};
function reducer(state, action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case documentConstants_1.GET_DOCUMENTS_SUCCESS:
            return __assign(__assign({}, state), { documents: action.documents, loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case documentConstants_1.POST_DOCUMENT_SUCCESS:
        case documentConstants_1.PUT_DOCUMENT_SUCCESS:
            return __assign(__assign({}, state), { document: __assign(__assign({}, state.document), ("isDraft" in action && { isDraft: action.isDraft })), loadings: __assign(__assign({}, state.loadings), { post: false }) });
        case documentConstants_1.DELETE_DOCUMENT_SUCCESS:
            return __assign(__assign({}, state), { documentsDescendants: action.documentsDescendants });
        case documentConstants_1.GET_DOCUMENT_STREAM_SUCCESS:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case documentConstants_1.GET_DOCUMENT_SUCCESS:
            return __assign(__assign({}, state), { document: action.document, loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case documentConstants_1.GET_DOCUMENT_DESCENDANTS_SUCCESS:
            return __assign(__assign({}, state), { documentsDescendants: action.documentsDescendants });
        case documentConstants_1.GET_DOCUMENT_TOKEN_SUCCESS:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = false, _a)), documentToken: action.token });
        case documentConstants_1.RESET_DOCUMENT_VALIDATION:
            return __assign(__assign({}, state), { validation: initialValidation });
        case documentConstants_1.GET_DOCUMENTS_LOADING:
        case documentConstants_1.POST_DOCUMENT_LOADING:
        case documentConstants_1.PUT_DOCUMENT_LOADING:
        case documentConstants_1.DOCUMENT_DUPLICATE_LOADING:
        case documentConstants_1.GET_DOCUMENT_LOADING:
        case documentConstants_1.GET_DOCUMENT_TOKEN_LOADING:
        case documentConstants_1.GET_BUNDLE_SECTIONS_LOADING:
        case documentConstants_1.CHANGE_BUNDLE_SECTION_TITLE_LOADING:
        case documentConstants_1.CHANGE_BUNDLE_SECTION_ORDER_LOADING:
        case documentConstants_1.ADD_BUNDLE_SECTION_LOADING:
        case documentConstants_1.DELETE_BUNDLE_SECTION_LOADING:
        case documentConstants_1.UPLOAD_DOCUMENTS_TO_JURIST_IQ_GROUP_LOADING:
        case documentConstants_1.DATES_FOUND_ON_DOCUMENT_LOADING:
        case documentConstants_1.UPLOAD_DOCUMENTS_AND_START_JOBS_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_b = {}, _b[action.loadingType] = true, _b)), document: initialDocument });
        case documentConstants_1.GET_DOCUMENT_STREAM_LOADING:
        case documentConstants_1.GET_BUNDLE_SECTIONS_DROPDOWN_LOADING:
        case documentConstants_1.STORE_DOCUMENT_FROM_TOOL_LOADING:
        case documentConstants_1.GET_CLEAN_OPTIONS_LOADING:
        case documentConstants_1.POST_DRAFT_DOCUMENT_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_c = {}, _c[action.loadingType] = true, _c)) });
        case documentConstants_1.GET_DOCUMENTS_FAILED:
        case documentConstants_1.POST_DOCUMENT_FAILED:
        case documentConstants_1.PUT_DOCUMENT_FAILED:
        case documentConstants_1.GET_DOCUMENT_TOKEN_FAILED:
        case documentConstants_1.DELETE_DOCUMENT_FAILED:
        case documentConstants_1.GET_DOCUMENT_FAILED:
        case documentConstants_1.FORCE_DELETE_DOCUMENT_FAILED:
        case documentConstants_1.GET_DOCUMENT_DESCENDANTS_FAILED:
        case documentConstants_1.GET_DOCUMENT_DROPDOWN_FAILED:
        case documentConstants_1.POST_DOCUMENT_NOTE_FAILED:
        case documentConstants_1.DELETE_DOCUMENT_NOTE_FAILED:
        case documentConstants_1.DOCUMENT_DUPLICATE_FAILED:
        case documentConstants_1.GET_BUNDLE_SECTIONS_FAILED:
        case documentConstants_1.CHANGE_BUNDLE_SECTION_TITLE_FAILED:
        case documentConstants_1.CHANGE_BUNDLE_SECTION_ORDER_FAILED:
        case documentConstants_1.ADD_BUNDLE_SECTION_FAILED:
        case documentConstants_1.UPLOAD_DOCUMENTS_TO_JURIST_IQ_GROUP_FAILED:
        case documentConstants_1.DELETE_BUNDLE_SECTION_FAILED:
        case documentConstants_1.GET_BUNDLE_SECTIONS_DROPDOWN_FAILED:
        case documentConstants_1.DATES_FOUND_ON_DOCUMENT_FAILED:
        case documentConstants_1.DOCUMENTS_ON_HOLD_FAILED:
        case documentConstants_1.GET_CLEAN_OPTIONS_FAILED:
        case documentConstants_1.UPLOAD_DOCUMENTS_AND_START_JOBS_SUCCESS:
        case documentConstants_1.UPLOAD_DOCUMENTS_AND_START_JOBS_FAILED:
        case documentConstants_1.POST_DRAFT_DOCUMENT_FAILED:
            return __assign(__assign({}, state), { loadings: action.loadingType
                    ? __assign(__assign({}, state.loadings), (_d = {}, _d[action.loadingType] = false, _d)) : document_1.initialDocumentLoadings });
        case documentConstants_1.UPLOAD_DOCUMENTS_TO_JURIST_IQ_GROUP_SUCCESS:
            return __assign(__assign({}, state), { loadings: document_1.initialDocumentLoadings, documents: __spreadArray(__spreadArray([], state.documents, true), action.documents, true) });
        case documentConstants_1.CHANGE_DOCUMENT: {
            if (action.attr === "initial") {
                var tempDocument = __assign({}, initialDocument);
                tempDocument.initial = true;
                return __assign(__assign({}, state), { document: tempDocument });
            }
            return __assign(__assign({}, state), { document: __assign(__assign({}, state.document), (_e = {}, _e[action.attr] = action.val, _e)) });
        }
        case documentConstants_1.GET_DOCUMENT_DROPDOWN_SUCCESS:
        case documentConstants_1.CHANGE_DOCUMENT_OPTIONS:
            return __assign(__assign({}, state), { documentOptions: action.documents });
        case documentConstants_1.CLEAR_DOCUMENT_DESCENDANTS:
            return __assign(__assign({}, state), { documentsDescendants: initialState.documentsDescendants });
        case documentConstants_1.FORCE_DELETE_DOCUMENT_SUCCESS:
            return __assign(__assign({}, state), { documents: state.documents.filter(function (document) { return document.id !== action.id; }) });
        case documentConstants_1.POST_DOCUMENT_NOTE_SUCCESS:
            return __assign(__assign({}, state), { document: __assign(__assign({}, state.document), { notes: __spreadArray(__spreadArray([], state.document.notes, true), [action.note], false) }) });
        case documentConstants_1.DELETE_DOCUMENT_NOTE_SUCCESS:
            return __assign(__assign({}, state), { document: __assign(__assign({}, state.document), { notes: state.document.notes.filter(function (note) { return note.id !== action.note.id; }) }) });
        case documentConstants_1.DOCUMENT_DUPLICATE_SUCCESS:
            return __assign(__assign({}, state), { documents: action.document
                    ? __spreadArray(__spreadArray([], state.documents, true), [action.document], false) : state.documents, loadings: document_1.initialDocumentLoadings });
        case documentConstants_1.GET_BUNDLE_SECTIONS_SUCCESS:
            return __assign(__assign({}, state), { bundleSections: action.bundleSections, loadings: document_1.initialDocumentLoadings });
        case documentConstants_1.CHANGE_BUNDLE_SECTION_TITLE_SUCCESS:
            return __assign(__assign({}, state), { bundleSections: state.bundleSections.map(function (bundleSection) {
                    if (bundleSection.id === action.bundleSection.id) {
                        return action.bundleSection;
                    }
                    return bundleSection;
                }), loadings: document_1.initialDocumentLoadings });
        case documentConstants_1.CHANGE_BUNDLE_SECTION_ORDER_SUCCESS:
            return __assign(__assign({}, state), { bundleSections: action.bundleSections, loadings: document_1.initialDocumentLoadings });
        case documentConstants_1.ADD_BUNDLE_SECTION_SUCCESS:
            return __assign(__assign({}, state), { bundleSections: __spreadArray(__spreadArray([], state.bundleSections, true), [action.bundleSection], false), loadings: document_1.initialDocumentLoadings });
        case documentConstants_1.DELETE_BUNDLE_SECTION_SUCCESS:
            return __assign(__assign({}, state), { bundleSections: action.bundleSections, loadings: document_1.initialDocumentLoadings });
        case documentConstants_1.GET_BUNDLE_SECTIONS_DROPDOWN_SUCCESS:
            return __assign(__assign({}, state), { bundleSectionOptions: action.bundleSectionOptions, loadings: document_1.initialDocumentLoadings });
        case (0, folderConstants_1.moveElementToDestroyedParentFailed)("documents"):
        case (0, folderConstants_1.postFolderFailed)("documents"):
        case (0, folderConstants_1.putFolderTitleFailed)("documents"):
        case (0, folderConstants_1.putFolderLocationFailed)("documents"):
        case (0, folderConstants_1.putFolderElementLocationFailed)("documents"):
        case (0, folderConstants_1.duplicateFolderFailed)("documents"):
            return __assign(__assign({}, state), { loadings: document_1.initialDocumentLoadings });
        case (0, folderConstants_1.deleteFolderFailed)("documents"):
            return __assign(__assign({}, state), { deletingFolder: 0, loadings: document_1.initialDocumentLoadings });
        case (0, folderConstants_1.deleteFolderLoading)("documents"):
            return __assign(__assign({}, state), { deletingFolder: 0, loadings: __assign(__assign({}, state.loadings), (_f = {}, _f[action.loadings] = true, _f)) });
        case (0, folderConstants_1.moveElementToDestroyedParentLoading)("documents"):
        case (0, folderConstants_1.putFolderLocationLoading)("documents"):
        case (0, folderConstants_1.putFolderElementLocationLoading)("documents"):
        case (0, folderConstants_1.putFolderTitleLoading)("documents"):
        case (0, folderConstants_1.postFolderLoading)("documents"):
        case (0, folderConstants_1.duplicateFolderLoading)("documents"):
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_g = {}, _g[action.loadings] = true, _g)) });
        case (0, folderConstants_1.postFolderSuccess)("documents"):
            return __assign(__assign({}, state), { loadings: document_1.initialDocumentLoadings, documents: __spreadArray([action.folder], state.documents, true) });
        case (0, folderConstants_1.putFolderTitleSuccess)("documents"):
            return __assign(__assign({}, state), { loadings: document_1.initialDocumentLoadings, documents: state.documents.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderLocationSuccess)("documents"):
            return __assign(__assign({}, state), { loadings: document_1.initialDocumentLoadings, documents: state.documents.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderElementLocationSuccess)("documents"):
            return __assign(__assign({}, state), { loadings: document_1.initialDocumentLoadings, documents: state.documents.map(function (c) {
                    if (!c.isFolder && c.id === action.id) {
                        return __assign(__assign({}, c), { folderId: action.folderId });
                    }
                    return c;
                }) });
        case (0, folderConstants_1.deleteFolderSuccess)("documents"): {
            if (action.descendants && action.descendants.length > 0) {
                return __assign(__assign({}, state), { loadings: document_1.initialDocumentLoadings, deletingFolder: action.folder.id, documentsDescendants: action.descendants });
            }
            if (action.deleteChildren) {
                return __assign(__assign({}, state), { loadings: document_1.initialDocumentLoadings, deletingFolder: 0, documents: state.documents
                        .filter(function (c) { return c.id !== action.folder.id; })
                        .filter(function (c) { return c.folderId !== action.folder.id; }) });
            }
            return __assign(__assign({}, state), { loadings: document_1.initialDocumentLoadings, deletingFolder: 0, documents: state.documents
                    .filter(function (c) { return c.id !== action.folder.id; })
                    .map(function (c) {
                    return c.folderId === action.folder.id
                        ? __assign(__assign({}, c), { folderId: action.folder.folderId }) : c;
                }) });
        }
        case (0, folderConstants_1.moveElementToDestroyedParentSuccess)("documents"):
            return __assign(__assign({}, state), { loadings: document_1.initialDocumentLoadings, documents: state.documents.map(function (c) {
                    if (c.id === action.id) {
                        return __assign(__assign({}, c), { folderId: action.folderId });
                    }
                    return c;
                }) });
        case (0, folderConstants_1.duplicateFolderSuccess)("documents"):
            return __assign(__assign({}, state), { loadings: document_1.initialDocumentLoadings, documents: __spreadArray(__spreadArray([], action.folder, true), state.documents, true) });
        case documentConstants_1.DATES_FOUND_ON_DOCUMENT_SUCCESS:
            return __assign(__assign({}, state), { validation: __assign(__assign({}, state.validation), { datesFoundOnDocument: action.datesFromDocument }), loadings: __assign(__assign({}, state.loadings), { modal: false }) });
        case documentConstants_1.SET_DOCUMENTS_ON_HOLD:
            return __assign(__assign({}, state), { validation: __assign(__assign({}, state.validation), { documentsOnHold: __spreadArray(__spreadArray([], state.validation.documentsOnHold, true), action.documents, true) }) });
        case documentConstants_1.SET_DOCUMENTS_AWAITING_VALIDATION:
            return __assign(__assign({}, state), { validation: __assign(__assign({}, state.validation), { currentDocumentForValidation: action.currentDocumentForValidation }) });
        case documentConstants_1.REMOVE_DOCUMENT_ON_HOLD: {
            return __assign(__assign({}, state), { validation: __assign(__assign({}, state.validation), { documentsOnHold: state.validation.documentsOnHold.filter(function (document) { return document.id !== action.id; }) }) });
        }
        case documentConstants_1.AWAITED_DOCUMENTS_CLEARED:
            return __assign(__assign({}, state), { validation: initialValidation });
        case documentConstants_1.STORE_DOCUMENT_FROM_TOOL_SUCCESS:
            return __assign(__assign(__assign({}, state), { loadings: action.loadingType
                    ? __assign(__assign({}, state.loadings), (_h = {}, _h[action.loadingType] = false, _h)) : state.loadings }), (action.format === "option"
                ? {
                    documentOptions: (0, objectIterationMethods_1.getValues)(Object.fromEntries(__spreadArray(__spreadArray([], state.documentOptions, true), action.documents, true).map(function (doc) { return [doc.id, doc]; }))),
                }
                : {
                    documents: state.documents.concat(action.documents),
                }));
        case documentConstants_1.GET_CLEAN_OPTIONS_SUCCESS:
            return __assign(__assign({}, state), { loadings: action.loadingType
                    ? __assign(__assign({}, state.loadings), (_j = {}, _j[action.loadingType] = false, _j)) : state.loadings, documentOptions: (0, objectIterationMethods_1.getValues)(Object.fromEntries(__spreadArray(__spreadArray([], state.documentOptions, true), action.documents, true).map(function (doc) { return [doc.id, doc]; }))) });
        case documentConstants_1.POST_DRAFT_DOCUMENT_SUCCESS:
            return __assign(__assign({}, state), { documents: __spreadArray(__spreadArray([], action.documents, true), state.documents, true), loadings: action.loadingType
                    ? __assign(__assign({}, state.loadings), (_k = {}, _k[action.loadingType] = false, _k)) : state.loadings });
        case documentConstants_1.DOCUMENT_UPDATE_FILE_PROGRESS_UPLOADS: {
            var newFiles = state.uploadProgress.map(function (file) {
                if (("id" in file.file && file.file.id === action.name) ||
                    file.file.name === action.name) {
                    return __assign(__assign({}, file), { progress: action.progress });
                }
                return file;
            });
            return __assign(__assign({}, state), { uploadProgress: newFiles });
        }
        case documentConstants_1.DOCUMENT_SET_FILE_UPLOADS:
            return __assign(__assign({}, state), { uploadProgress: action.files });
        case documentConstants_1.CLEAR_DOCUMENT:
            return __assign(__assign({}, state), { document: initialDocument });
        default: {
            return state;
        }
    }
}
exports.default = reducer;
