"use strict";
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-return-assign */
/* eslint-disable import/prefer-default-export */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCutCopyPaste = void 0;
var react_1 = require("react");
var reactflow_1 = require("reactflow");
function getSelectedGraph(selectedNodes, elements) {
    var allEdges = [];
    var allNodeMap = {};
    elements.forEach(function (node) {
        if ((0, reactflow_1.isEdge)(node)) {
            allEdges.push(node);
        }
        else {
            allNodeMap[node.id] = node;
        }
    });
    var edgeMap = {};
    var selectedNodeIds = [];
    var nodes = [];
    selectedNodes.forEach(function (node) {
        if (!(0, reactflow_1.isEdge)(node)) {
            selectedNodeIds.push(node.id);
            var connectedEdges = (0, reactflow_1.getConnectedEdges)([node], allEdges);
            connectedEdges.forEach(function (edge) { return (edgeMap[edge.id] = edge); });
            nodes.push(allNodeMap[node.id]);
        }
    });
    // pick edges which has both nodes present in selectedNodes
    Object.values(edgeMap).forEach(function (edge) {
        if (selectedNodeIds.includes(edge.source) && selectedNodeIds.includes(edge.target)) {
            nodes.push(edge);
        }
    });
    return nodes;
}
function useShortcut(keyCode, callback) {
    var _a = (0, react_1.useState)(false), didRun = _a[0], setDidRun = _a[1];
    var store = (0, reactflow_1.useStoreApi)();
    var target = store.getState().domNode;
    var shouldRun = (0, reactflow_1.useKeyPress)(keyCode, { target: target });
    (0, react_1.useEffect)(function () {
        if (shouldRun && !didRun) {
            callback();
            setDidRun(true);
        }
        else {
            setDidRun(shouldRun);
        }
    }, [shouldRun, didRun, callback]);
}
function useCutCopyPaste(_addElements, rfInstance, contextAnchor) {
    var _this = this;
    var mousePosRef = (0, react_1.useRef)({ clientX: 0, clientY: 0 });
    var store = (0, reactflow_1.useStoreApi)();
    var getNodesToCutOrCopy = (0, react_1.useCallback)(function (elements) {
        if (!rfInstance) {
            return [];
        }
        var nodeElements = rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getNodes();
        var nodesToCut = __spreadArray([], elements, true);
        var groupNodes = elements.filter(function (sn) { return sn.type === "customgroup"; });
        var _loop_1 = function (groupNode) {
            var children = nodeElements.filter(function (n) { return n.parentNode === groupNode.id; });
            nodesToCut.push.apply(nodesToCut, children);
        };
        for (var _i = 0, groupNodes_1 = groupNodes; _i < groupNodes_1.length; _i++) {
            var groupNode = groupNodes_1[_i];
            _loop_1(groupNode);
        }
        return nodesToCut;
    }, [rfInstance]);
    var formatDataForPasting = (0, react_1.useCallback)(function (isFromHotkey, nodesToCutOrCopy) {
        if (!rfInstance) {
            return "[]";
        }
        var nodeElements = rfInstance.getNodes();
        var edgeElements = rfInstance.getEdges();
        var formattedData = isFromHotkey
            ? JSON.stringify(getSelectedGraph(nodesToCutOrCopy, __spreadArray(__spreadArray([], nodeElements, true), edgeElements, true)))
            : JSON.stringify(nodesToCutOrCopy);
        return formattedData;
    }, []);
    var cut = (0, react_1.useCallback)(function (element) {
        if (element === void 0) { element = null; }
        if (!rfInstance) {
            return;
        }
        var selectedNodes = rfInstance.getNodes().filter(function (n) { return n.selected; });
        if (selectedNodes.length > 0 || element) {
            var isFromHotkey = element === null;
            var elementsToCut = (isFromHotkey ? selectedNodes : [element]);
            var nodesToCut = getNodesToCutOrCopy(elementsToCut);
            var data = formatDataForPasting(isFromHotkey, nodesToCut);
            navigator.clipboard.writeText(data);
            var onNodesDelete = store.getState().onNodesDelete;
            var onNodesChange = store.getState().onNodesChange;
            onNodesDelete(nodesToCut);
            onNodesChange(nodesToCut.map(function (el) { return ({ id: el.id, type: "remove" }); }));
        }
    }, [formatDataForPasting, getNodesToCutOrCopy, rfInstance]);
    var copy = (0, react_1.useCallback)(function (element) {
        if (element === void 0) { element = null; }
        return __awaiter(_this, void 0, void 0, function () {
            var selectedNodes, isFromHotkey, elementsToCopy, nodesToCopy, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!rfInstance) {
                            return [2 /*return*/];
                        }
                        selectedNodes = rfInstance.getNodes().filter(function (n) { return n.selected; });
                        if (!(selectedNodes.length > 0 || element)) return [3 /*break*/, 2];
                        isFromHotkey = element === null;
                        elementsToCopy = (isFromHotkey ? selectedNodes : [element]);
                        nodesToCopy = getNodesToCutOrCopy(elementsToCopy);
                        data = formatDataForPasting(isFromHotkey, nodesToCopy);
                        return [4 /*yield*/, navigator.clipboard.writeText(data)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }, [getNodesToCutOrCopy, formatDataForPasting, rfInstance]);
    var handlePaste = function (ev, elements) {
        var _a, _b;
        var target = ev.target;
        // apparently nodes input element is h6
        var isInput = ["h6", "input"].some(function (el) { return !!(target === null || target === void 0 ? void 0 : target.closest(el)); });
        if (isInput || typeof elements !== "object") {
            return;
        }
        if (rfInstance) {
            var mouse = mousePosRef.current;
            var x = ((_a = mouse.clientX) !== null && _a !== void 0 ? _a : contextAnchor === null || contextAnchor === void 0 ? void 0 : contextAnchor.x);
            var y = ((_b = mouse.clientY) !== null && _b !== void 0 ? _b : contextAnchor === null || contextAnchor === void 0 ? void 0 : contextAnchor.y);
            var position_1 = rfInstance.screenToFlowPosition({
                x: x,
                y: y,
            });
            var sortedByY = __spreadArray([], JSON.parse(JSON.stringify(elements)), true).filter(function (e) { return (0, reactflow_1.isNode)(e); })
                .sort(function (a, b) { return b.position.y - a.position.y; });
            if (sortedByY.some(function (el) { return el.type === "customgroup"; })) {
                sortedByY = sortedByY.filter(function (e) { return !e.parentNode; });
            }
            var topNode_1 = sortedByY[sortedByY.length - 1];
            var mostLeftNode_1 = sortedByY.reduce(function (prev, current) {
                return prev.position.x < current.position.x ? prev : current;
            });
            var elementsToAdd = __spreadArray([], JSON.parse(JSON.stringify(elements)), true);
            var now_1 = Date.now();
            elementsToAdd.map(function (element) {
                var newEl = __assign({}, element);
                if ((0, reactflow_1.isEdge)(newEl)) {
                    newEl.id = "".concat(newEl.id, "_").concat(now_1, "-edit");
                    newEl.source = "".concat(newEl.source, "_").concat(now_1, "-edit");
                    newEl.target = "".concat(newEl.target, "_").concat(now_1, "-edit");
                }
                else {
                    newEl.id = "".concat(newEl.id, "_").concat(now_1, "-edit");
                    var xDistanceToTopNode = newEl.position.x - mostLeftNode_1.position.x;
                    var yDistanceToTopNode = newEl.position.y - topNode_1.position.y;
                    newEl.position.x = position_1.x + xDistanceToTopNode;
                    newEl.position.y = position_1.y + yDistanceToTopNode;
                }
                return newEl;
            });
            _addElements(elementsToAdd);
        }
    };
    var paste = function (ev) { return __awaiter(_this, void 0, void 0, function () {
        var elementsToAdd, _a, _b, error_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    _b = (_a = JSON).parse;
                    return [4 /*yield*/, navigator.clipboard.readText()];
                case 1:
                    elementsToAdd = _b.apply(_a, [_c.sent()]);
                    handlePaste(ev, elementsToAdd);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _c.sent();
                    console.error(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        var rfDomNode = store.getState().domNode;
        var events = ["cut", "copy"];
        if (rfDomNode) {
            var preventDefault_1 = function (e) {
                var _a;
                var t = e.target;
                if ((_a = t === null || t === void 0 ? void 0 : t.classList) === null || _a === void 0 ? void 0 : _a.contains("react-flow__node")) {
                    e.preventDefault();
                }
            };
            var onMouseMove_1 = function (event) {
                mousePosRef.current = {
                    clientX: event.clientX,
                    clientY: event.clientY,
                };
            };
            for (var _i = 0, events_1 = events; _i < events_1.length; _i++) {
                var event = events_1[_i];
                rfDomNode.addEventListener(event, preventDefault_1);
            }
            rfDomNode.addEventListener("mousemove", onMouseMove_1);
            document.addEventListener("paste", paste);
            return function () {
                for (var _i = 0, events_2 = events; _i < events_2.length; _i++) {
                    var event = events_2[_i];
                    rfDomNode.removeEventListener(event, preventDefault_1);
                }
                document.removeEventListener("paste", paste);
                rfDomNode.removeEventListener("mousemove", onMouseMove_1);
            };
        }
    }, [cut, copy, paste]);
    useShortcut(["Meta+x", "Control+x"], cut);
    useShortcut(["Meta+c", "Control+c"], copy);
    return { cut: cut, copy: copy, paste: paste };
}
exports.useCutCopyPaste = useCutCopyPaste;
