"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.onPostDrilldownNode = exports.onSendBackward = exports.onBringForward = exports.onSendToBack = exports.onBringToFront = exports.onUploadAiPrompt = exports.onNewBuildingBlocksAndTabs = exports.onImportStructureSuccess = exports.onCvrSuccess = exports.onPutNodeDisplayName = exports.onUpdateTitleStep = exports.onDetachNodes = exports.onAttachNodes = exports.onPutGroupNode = exports.onPostGroupNode = exports.onNodeResizeMultiple = exports.onNodeResize = exports.onUserFinishEditingEdges = exports.onUserFinishEditingNodes = exports.onUserEditingEdges = exports.onUserEditingNodes = exports.onRemoveCursor = exports.onUpdateUserPositionRelative = exports.onUpdateUserPosition = exports.onUserJoinsRoom = exports.onRemoveTabForWorkspace = exports.onRemoveTab = exports.onUpdateTabTitle = exports.onStoreTab = exports.onPutText = exports.onPostText = exports.onPutSticky = exports.onPostSticky = exports.onPostIcon = exports.onPostFile = exports.onImportFile = exports.onUpdateEdgePosition = exports.onEdgePathDragged = exports.onMoveEdgeLabel = exports.onUpdateEdge = exports.onPostEdge = exports.onAnalyseAlerts = exports.onAddElements = exports.onPostStandardNode = exports.onElementsUpdatePosition = exports.onUpdateMultipleNodes = exports.onUpdateNode = exports.onStoreNode = exports.onSoftRemoveEdges = exports.onSoftRemoveNodes = void 0;
exports.onToggleShowBodyOutlineInPptxSuccess = exports.onToggleShowTitleOutlineInPptxSuccess = exports.onToggleIncludeLegendsInPptxSuccess = exports.onNewDocumentOptions = exports.onRedo = exports.onUndo = exports.onDuplicateNodeOnEdge = exports.onDuplicateFromHandle = exports.onPostNodeDocument = exports.onPutFitSizeToLabel = exports.onGroupNodeMutable = exports.onGroupNodeImmutable = exports.onDisconnect = exports.onUpdateCursorRoom = exports.onUserToggleActive = exports.onUserDisconnects = exports.onDrilldownDisband = exports.onDrilldown = void 0;
var types = __importStar(require("../workspaceConstants"));
var documentTypes = __importStar(require("../../../Documents/reducers/documentConstants"));
var alertActions_1 = require("@pages/Alerts/reducers/alertActions");
var sharedAction_http_1 = require("../sharedAction.http");
var workspaceActions_websocket_1 = require("./workspaceActions.websocket");
var socketActions_1 = require("@redux/actions/socketActions");
var constants_1 = require("@api/socket/constants");
function onSoftRemoveNodes() {
    return function (dispatch) {
        return function (_a) {
            var elements = _a.elements;
            var deletedNodes = elements.map(function (e) { return e.typeId; });
            dispatch({
                type: types.SOFT_DELETE_WORKSPACE_NODES_SUCCESS,
                deletedNodes: deletedNodes,
            });
        };
    };
}
exports.onSoftRemoveNodes = onSoftRemoveNodes;
function onSoftRemoveEdges() {
    return function (dispatch) {
        return function (_a) {
            var elements = _a.elements, nodes = _a.nodes;
            var deletedEdges = elements.map(function (e) { return e.typeId; });
            dispatch({
                type: types.SOFT_DELETE_WORKSPACE_EDGES_SUCCESS,
                deletedEdges: deletedEdges,
                alteredNodes: nodes,
            });
        };
    };
}
exports.onSoftRemoveEdges = onSoftRemoveEdges;
function onStoreNode() {
    return function (dispatch) {
        return function (node) {
            dispatch({ type: types.WORKSPACE_POST_NODE_SUCCESS, node: node });
        };
    };
}
exports.onStoreNode = onStoreNode;
function onUpdateNode() {
    return function (dispatch) {
        return function (_a) {
            var node = _a.node, standardNodes = _a.standardNodes;
            dispatch({ type: types.WORKSPACE_PUT_NODE_SUCCESS, node: node, standardNodes: standardNodes });
        };
    };
}
exports.onUpdateNode = onUpdateNode;
function onUpdateMultipleNodes() {
    return function (dispatch) {
        return function (payload) {
            dispatch({ type: types.WORKSPACE_PUT_NODE_SUCCESS, payload: payload });
        };
    };
}
exports.onUpdateMultipleNodes = onUpdateMultipleNodes;
function onElementsUpdatePosition() {
    return function (dispatch) {
        return function (_a) {
            var elements = _a.elements, edges = _a.edges;
            dispatch({ type: types.SAVE_NODE_POSITION_SUCCESS, nodes: elements, edges: edges });
        };
    };
}
exports.onElementsUpdatePosition = onElementsUpdatePosition;
function onPostStandardNode() {
    return function (dispatch) { return function (node) {
        dispatch({
            type: types.POST_STANDARD_NODE_SUCCESS,
            node: node,
        });
    }; };
}
exports.onPostStandardNode = onPostStandardNode;
function onAddElements() {
    return function (dispatch) {
        return function (elements) {
            var nodes = elements.nodes, edges = elements.edges;
            dispatch({
                type: types.WORKSPACE_ADD_ELEMENTS_SUCCESS,
                nodes: nodes,
                edges: edges,
            });
        };
    };
}
exports.onAddElements = onAddElements;
function onAnalyseAlerts(noAlert) {
    return function (dispatch) { return function (alerts) {
        dispatch((0, alertActions_1.analyseAlertsSuccess)(alerts, noAlert));
    }; };
}
exports.onAnalyseAlerts = onAnalyseAlerts;
function onPostEdge() {
    return function (dispatch) {
        return function (_a) {
            var edges = _a.edges, nodes = _a.nodes;
            dispatch({ type: types.POST_EDGE_SUCCESS, edges: edges, nodes: nodes });
        };
    };
}
exports.onPostEdge = onPostEdge;
function onUpdateEdge(user, location) {
    return function (dispatch) {
        return function (_a) {
            var edges = _a.edges;
            dispatch({ type: types.PUT_EDGE_SUCCESS, edges: edges });
            dispatch((0, sharedAction_http_1.getRelationships)(user, location));
        };
    };
}
exports.onUpdateEdge = onUpdateEdge;
function onMoveEdgeLabel() {
    return function (dispatch) {
        return function (_a) {
            var edge = _a.edge;
            dispatch({ type: types.EDGE_LABEL_MOVE_SUCCESS, edge: edge });
        };
    };
}
exports.onMoveEdgeLabel = onMoveEdgeLabel;
function onEdgePathDragged() {
    return function (dispatch) {
        return function (_a) {
            var edge = _a.edge;
            dispatch({ type: types.EDGE_PATH_DRAG_SUCCESS, edge: edge });
        };
    };
}
exports.onEdgePathDragged = onEdgePathDragged;
function onUpdateEdgePosition() {
    return function (dispatch) {
        return function (_a) {
            var edge = _a.edge, nodes = _a.nodes;
            dispatch({ type: types.PUT_EDGE_POSITION_SUCCESS, edge: edge, nodes: nodes });
        };
    };
}
exports.onUpdateEdgePosition = onUpdateEdgePosition;
function onImportFile() {
    return function (dispatch) { return function (nodes) {
        dispatch({ type: types.IMPORT_WORKSPACE_FILES_SUCCESS, nodes: nodes });
    }; };
}
exports.onImportFile = onImportFile;
function onPostFile() {
    return function (dispatch) {
        return function (node) {
            dispatch({ type: types.WORKSPACE_POST_FILE_SUCCESS, node: node });
        };
    };
}
exports.onPostFile = onPostFile;
function onPostIcon() {
    return function (dispatch) { return function (node) {
        dispatch({ type: types.WORKSPACE_POST_ICON_SUCCESS, node: node });
    }; };
}
exports.onPostIcon = onPostIcon;
function onPostSticky() {
    return function (dispatch) {
        return function (sticky) {
            dispatch({ type: types.WORKSPACE_POST_NODE_SUCCESS, node: sticky });
        };
    };
}
exports.onPostSticky = onPostSticky;
function onPutSticky() {
    return function (dispatch) {
        return function (_a) {
            var sticky = _a.node;
            dispatch({ type: types.WORKSPACE_PUT_STICKY_SUCCESS, sticky: sticky });
        };
    };
}
exports.onPutSticky = onPutSticky;
function onPostText() {
    return function (dispatch) {
        return function (node) {
            dispatch({ type: types.WORKSPACE_POST_NODE_SUCCESS, node: node });
        };
    };
}
exports.onPostText = onPostText;
function onPutText() {
    return function (dispatch) {
        return function (_a) {
            var node = _a.node;
            dispatch({
                type: types.WORKSPACE_PUT_NODE_SUCCESS,
                node: node,
            });
        };
    };
}
exports.onPutText = onPutText;
function onStoreTab() {
    return function (dispatch) {
        return function (result) {
            dispatch({ type: types.POST_WORKSPACE_TAB_FOR_WORKSPACE, tab: result.tab });
        };
    };
}
exports.onStoreTab = onStoreTab;
function onUpdateTabTitle() {
    return function (dispatch) {
        return function (tab) {
            dispatch({ type: types.PUT_WORKSPACE_TAB_TITLE_FOR_WORKSPACE, tab: tab });
        };
    };
}
exports.onUpdateTabTitle = onUpdateTabTitle;
function onRemoveTab(workspaceTabs) {
    return function (dispatch) {
        return function (_a) {
            var tab = _a.tab, nodes = _a.nodes, edges = _a.edges, removedTabId = _a.removedTabId;
            var removedTab = workspaceTabs.find(function (t) { return t.value === removedTabId; });
            dispatch({
                type: types.DELETE_WORKSPACE_TAB_SUCCESS,
                removedTab: removedTab,
                tab: tab,
                nodes: nodes,
                edges: edges,
            });
            dispatch((0, workspaceActions_websocket_1.updateTabRoom)(tab.value));
        };
    };
}
exports.onRemoveTab = onRemoveTab;
function onRemoveTabForWorkspace() {
    return function (dispatch) { return function (removedTabId) {
        dispatch({ type: types.REMOVE_WORKSPACE_TAB_FOR_WORKSPACE, tabId: removedTabId });
    }; };
}
exports.onRemoveTabForWorkspace = onRemoveTabForWorkspace;
function onUserJoinsRoom() {
    return function (dispatch) { return function (response) {
        var cursorElement = (0, workspaceActions_websocket_1.createCursor)(response);
        dispatch({
            type: types.USER_JOIN_ROOM,
            socketId: response.id,
            cursor: cursorElement,
        });
    }; };
}
exports.onUserJoinsRoom = onUserJoinsRoom;
function onUpdateUserPosition() {
    return function (dispatch) { return function (response) {
        dispatch(__assign({ type: types.USER_UPDATE_POSITION }, response));
    }; };
}
exports.onUpdateUserPosition = onUpdateUserPosition;
function onUpdateUserPositionRelative() {
    return function (dispatch) {
        return function (response) {
            dispatch(__assign({ type: types.USER_UPDATE_POSITION_RELATIVE }, response));
        };
    };
}
exports.onUpdateUserPositionRelative = onUpdateUserPositionRelative;
function onRemoveCursor() {
    return function (dispatch) { return function (response) {
        dispatch({ type: types.REMOVE_CONNECTED_USER, id: response.id });
        dispatch({ type: types.REMOVE_USER_FROM_ELEMENTS, id: response.id });
    }; };
}
exports.onRemoveCursor = onRemoveCursor;
function onUserEditingNodes() {
    return function (dispatch) { return function (response) {
        dispatch({
            type: types.USER_EDIT_NODES,
            nodes: response.elementIds,
            id: response.sender,
        });
    }; };
}
exports.onUserEditingNodes = onUserEditingNodes;
function onUserEditingEdges() {
    return function (dispatch) { return function (response) {
        dispatch({
            type: types.USER_EDIT_EDGES,
            edges: response.elementIds,
            id: response.sender,
        });
    }; };
}
exports.onUserEditingEdges = onUserEditingEdges;
function onUserFinishEditingNodes() {
    return function (dispatch) { return function (response) {
        dispatch({
            type: types.USER_FINISH_EDIT_NODES,
            nodes: response.elementIds,
            id: response.sender,
        });
    }; };
}
exports.onUserFinishEditingNodes = onUserFinishEditingNodes;
function onUserFinishEditingEdges() {
    return function (dispatch) { return function (response) {
        dispatch({
            type: types.USER_FINISH_EDIT_EDGES,
            edges: response.elementIds,
            id: response.sender,
        });
    }; };
}
exports.onUserFinishEditingEdges = onUserFinishEditingEdges;
function onNodeResize() {
    return function (dispatch) {
        return function (_a) {
            var node = _a.element;
            dispatch({ type: types.SAVE_NODE_SIZE_SUCCESS, node: node });
        };
    };
}
exports.onNodeResize = onNodeResize;
function onNodeResizeMultiple() {
    return function (dispatch) {
        return function (_a) {
            var nodes = _a.elements;
            dispatch({ type: "WORKSPACE_RESIZE_MULTIPLE_NODES_SUCCESS", nodes: nodes });
        };
    };
}
exports.onNodeResizeMultiple = onNodeResizeMultiple;
function onPostGroupNode() {
    return function (dispatch) { return function (node) {
        dispatch({ type: types.WORKSPACE_POST_NODE_SUCCESS, node: node });
    }; };
}
exports.onPostGroupNode = onPostGroupNode;
function onPutGroupNode() {
    return function (dispatch) {
        return function (_a) {
            var node = _a.node;
            dispatch({ type: types.WORKSPACE_PUT_NODE_SUCCESS, node: node });
        };
    };
}
exports.onPutGroupNode = onPutGroupNode;
function onAttachNodes() {
    return function (dispatch) {
        return function (_a) {
            var element = _a.element;
            dispatch({ type: types.HANDLE_ATTACH_NODE_SUCCESS, element: element });
        };
    };
}
exports.onAttachNodes = onAttachNodes;
function onDetachNodes() {
    return function (dispatch) { return function (payload) {
        dispatch({ type: types.HANDLE_DETACH_NODES_SUCCESS, payload: payload });
    }; };
}
exports.onDetachNodes = onDetachNodes;
function onUpdateTitleStep() {
    return function (dispatch) {
        return function (_a) {
            var stepId = _a.stepId, title = _a.title;
            dispatch({ type: "UPDATE_WORKSPACE_STEP_TITLE", stepId: stepId, title: title });
        };
    };
}
exports.onUpdateTitleStep = onUpdateTitleStep;
function onPutNodeDisplayName() {
    return function (dispatch) {
        return function (_a) {
            var displayName = _a.displayName, width = _a.width, height = _a.height, id = _a.id;
            dispatch({
                type: types.CHANGE_NODE_DISPLAY_NAME,
                id: "".concat(id),
                width: width,
                height: height,
                displayName: displayName,
            });
        };
    };
}
exports.onPutNodeDisplayName = onPutNodeDisplayName;
function onCvrSuccess() {
    return function (dispatch) {
        return function (_a) {
            var nodes = _a.nodes, edges = _a.edges, relationships = _a.relationships, standardNodes = _a.standardNodes;
            dispatch({
                type: types.GET_CVR_NODES_SUCCESS,
                nodes: nodes,
                edges: edges,
                relationships: relationships,
                standardNodes: standardNodes,
            });
        };
    };
}
exports.onCvrSuccess = onCvrSuccess;
function onImportStructureSuccess() {
    return function (dispatch) {
        return function (_a) {
            var nodes = _a.nodes, edges = _a.edges, relationships = _a.relationships, standardNodes = _a.standardNodes, tabs = _a.tabs, unsettledLabels = _a.unsettledLabels;
            dispatch({
                type: types.IMPORT_STRUCTURE_SUCCESS,
                nodes: nodes,
                edges: edges,
                relationships: relationships,
                standardNodes: standardNodes,
                tabs: tabs,
                unsettledLabels: unsettledLabels,
            });
        };
    };
}
exports.onImportStructureSuccess = onImportStructureSuccess;
function onNewBuildingBlocksAndTabs() {
    return function (dispatch) {
        return function (_a) {
            var relationships = _a.relationships, standardNodes = _a.standardNodes, tabs = _a.tabs;
            dispatch({
                type: types.NEW_BUILDING_BLOCKS_AND_TABS,
                relationships: relationships,
                standardNodes: standardNodes,
                tabs: tabs,
            });
        };
    };
}
exports.onNewBuildingBlocksAndTabs = onNewBuildingBlocksAndTabs;
function onUploadAiPrompt() {
    return function (dispatch) {
        return function (_a) {
            var nodes = _a.nodes, edges = _a.edges;
            dispatch({
                type: types.AI_PROMPT_SUCCESS,
                elements: { nodes: nodes, edges: edges },
            });
        };
    };
}
exports.onUploadAiPrompt = onUploadAiPrompt;
function onBringToFront() {
    return function (dispatch) { return function (items) {
        dispatch({
            type: types.PUT_Z_INDEX_TO_FRONT_SUCCESS,
            items: items,
        });
    }; };
}
exports.onBringToFront = onBringToFront;
function onSendToBack() {
    return function (dispatch) { return function (items) {
        dispatch({
            type: types.PUT_Z_INDEX_TO_BACK_SUCCESS,
            items: items,
        });
    }; };
}
exports.onSendToBack = onSendToBack;
function onBringForward() {
    return function (dispatch) { return function (items) {
        dispatch({
            type: types.PUT_Z_INDEX_FORWARD_SUCCESS,
            items: items,
        });
    }; };
}
exports.onBringForward = onBringForward;
function onSendBackward() {
    return function (dispatch) { return function (items) {
        dispatch({
            type: types.PUT_Z_INDEX_BACKWARD_SUCCESS,
            items: items,
        });
    }; };
}
exports.onSendBackward = onSendBackward;
function onPostDrilldownNode() {
    return function (dispatch) {
        return function (_a) {
            var drilldownNode = _a.drilldownNode, nodesToHide = _a.nodesToHide, drilldownEdges = _a.drilldownEdges;
            dispatch({
                type: types.WORKSPACE_POST_DRILLDOWN_NODE_SUCCESS,
                drilldownNode: drilldownNode,
                nodesToHide: nodesToHide,
                drilldownEdges: drilldownEdges,
            });
        };
    };
}
exports.onPostDrilldownNode = onPostDrilldownNode;
function onDrilldown() {
    return function (dispatch) {
        return function (_a) {
            var nodesToShow = _a.nodesToShow, nodesToHide = _a.nodesToHide, drilldownNodeId = _a.drilldownNodeId, drilldownAction = _a.drilldownAction;
            dispatch({
                type: types.DRILLDOWN_SUCCESS,
                nodesToShow: nodesToShow,
                nodesToHide: nodesToHide,
                drilldownNodeId: drilldownNodeId,
                drilldownAction: drilldownAction,
            });
        };
    };
}
exports.onDrilldown = onDrilldown;
function onDrilldownDisband() {
    return function (dispatch) {
        return function (_a) {
            var nodesToShow = _a.nodesToShow, nodesToHide = _a.nodesToHide, drilldownNodeId = _a.drilldownNodeId;
            dispatch({
                type: types.DISBAND_DRILLDOWN_SUCCESS,
                nodesToShow: nodesToShow,
                nodesToHide: nodesToHide,
                drilldownNodeId: drilldownNodeId,
            });
        };
    };
}
exports.onDrilldownDisband = onDrilldownDisband;
function onUserDisconnects() {
    return function (dispatch) {
        return function (_a) {
            var id = _a.id;
            dispatch({ type: types.REMOVE_CONNECTED_USER, id: id });
            dispatch({ type: types.REMOVE_USER_FROM_ELEMENTS, id: id });
        };
    };
}
exports.onUserDisconnects = onUserDisconnects;
function onUserToggleActive() {
    return function (dispatch) {
        return function (_a) {
            var id = _a.id, active = _a.active;
            return dispatch({ type: types.USER_TOGGLE_ACTIVE, id: id, active: active });
        };
    };
}
exports.onUserToggleActive = onUserToggleActive;
function onUpdateCursorRoom() {
    return function (dispatch) {
        return function (_a) {
            var id = _a.id, room = _a.room;
            dispatch({ type: types.UPDATE_CURSOR_ROOM, id: id, room: room });
        };
    };
}
exports.onUpdateCursorRoom = onUpdateCursorRoom;
function onDisconnect() {
    return function (dispatch) { return function (reason) {
        if (reason === constants_1.IO_DISCONNECT)
            return;
        dispatch((0, socketActions_1.socketDisconnected)());
    }; };
}
exports.onDisconnect = onDisconnect;
function onGroupNodeImmutable() {
    return function (dispatch) { return function (groupNodes) {
        dispatch({ type: types.SET_IMMUTABLE_GROUP_NODES, groupNodes: groupNodes });
    }; };
}
exports.onGroupNodeImmutable = onGroupNodeImmutable;
function onGroupNodeMutable() {
    return function (dispatch) { return function (groupNodes) {
        dispatch({ type: types.SET_MUTABLE_GROUP_NODES, groupNodes: groupNodes });
    }; };
}
exports.onGroupNodeMutable = onGroupNodeMutable;
function onPutFitSizeToLabel() {
    return function (dispatch) { return function (response) {
        var width = response.width, height = response.height, fitSizeToLabel = response.fitSizeToLabel, id = response.id;
        dispatch({
            type: types.FIT_SIZE_TO_LABEL_SUCCESS,
            id: id.toString(),
            width: width,
            height: height,
            fitSizeToLabel: fitSizeToLabel,
        });
    }; };
}
exports.onPutFitSizeToLabel = onPutFitSizeToLabel;
function onPostNodeDocument() {
    return function (dispatch) { return function (response) {
        var dropDownOptions = response.dropDownOptions;
        dispatch({
            type: documentTypes.GET_DOCUMENT_DROPDOWN_SUCCESS,
            documents: dropDownOptions,
        });
    }; };
}
exports.onPostNodeDocument = onPostNodeDocument;
function onDuplicateFromHandle() {
    return function (dispatch) {
        return function (response) {
            var nodes = response.nodes, edges = response.edges;
            dispatch({ type: types.CREATE_DUPLICATE_FROM_HANDLE_SUCCESS, nodes: nodes, edges: edges });
        };
    };
}
exports.onDuplicateFromHandle = onDuplicateFromHandle;
function onDuplicateNodeOnEdge() {
    return function (dispatch) {
        return function (response) {
            var nodes = response.nodes, edges = response.edges;
            dispatch({ type: types.DUPLICATE_NODE_ON_EDGE_SUCCESS, nodes: nodes, edges: edges });
        };
    };
}
exports.onDuplicateNodeOnEdge = onDuplicateNodeOnEdge;
function onUndo() {
    return function (dispatch) { return function (payload) {
        dispatch({ type: types.WORKSPACE_UNDO_PAYLOAD, payload: payload });
    }; };
}
exports.onUndo = onUndo;
function onRedo() {
    return function (dispatch) { return function (payload) {
        dispatch({ type: types.WORKSPACE_REDO_PAYLOAD, payload: payload });
    }; };
}
exports.onRedo = onRedo;
function onNewDocumentOptions() {
    return function (dispatch) {
        return function (_a) {
            var documents = _a.documents;
            dispatch({
                type: documentTypes.GET_CLEAN_OPTIONS_SUCCESS,
                documents: documents,
            });
        };
    };
}
exports.onNewDocumentOptions = onNewDocumentOptions;
var onToggleIncludeLegendsInPptxSuccess = function () {
    return function (dispatch) {
        return function (_a) {
            var includeLegendsInPPTX = _a.includeLegendsInPPTX;
            dispatch({
                type: types.TOGGLE_INCLUDE_LEGENDS_IN_PPTX_SUCCESS,
                includeLegendsInPPTX: includeLegendsInPPTX,
            });
        };
    };
};
exports.onToggleIncludeLegendsInPptxSuccess = onToggleIncludeLegendsInPptxSuccess;
var onToggleShowTitleOutlineInPptxSuccess = function () {
    return function (dispatch) {
        return function (_a) {
            var showTitleOutlineInPPTX = _a.showTitleOutlineInPPTX;
            dispatch({
                type: types.TOGGLE_SHOW_TITLE_OUTLINE_IN_PPTX_SUCCESS,
                showTitleOutlineInPPTX: showTitleOutlineInPPTX,
            });
        };
    };
};
exports.onToggleShowTitleOutlineInPptxSuccess = onToggleShowTitleOutlineInPptxSuccess;
var onToggleShowBodyOutlineInPptxSuccess = function () {
    return function (dispatch) {
        return function (_a) {
            var showBodyOutlineInPPTX = _a.showBodyOutlineInPPTX;
            dispatch({
                type: types.TOGGLE_SHOW_BODY_OUTLINE_IN_PPTX_SUCCESS,
                showBodyOutlineInPPTX: showBodyOutlineInPPTX,
            });
        };
    };
};
exports.onToggleShowBodyOutlineInPptxSuccess = onToggleShowBodyOutlineInPptxSuccess;
