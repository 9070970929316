"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSelectionWidthOrHeight = exports.getSelectionCoordinate = exports.getRelativeCoordinate = exports.getSelectionRect = exports.getDirection = void 0;
// returns an array of two numbers (0, 1 or -1) representing the direction of the resize
// 0 = no change, 1 = increase, -1 = decrease
// eslint-disable-next-line import/prefer-default-export
function getDirection(_a) {
    var width = _a.width, prevWidth = _a.prevWidth, height = _a.height, prevHeight = _a.prevHeight, invertX = _a.invertX, invertY = _a.invertY;
    var deltaWidth = width - prevWidth;
    var deltaHeight = height - prevHeight;
    var direction = [
        deltaWidth > 0 ? 1 : deltaWidth < 0 ? -1 : 0,
        deltaHeight > 0 ? 1 : deltaHeight < 0 ? -1 : 0,
    ];
    if (deltaWidth && invertX) {
        direction[0] *= -1;
    }
    if (deltaHeight && invertY) {
        direction[1] *= -1;
    }
    return direction;
}
exports.getDirection = getDirection;
function getSelectionRect(nodes) {
    var rect = nodes.reduce(function (acc, n) {
        var width = n.width, height = n.height;
        var _a = n.position, x = _a.x, y = _a.y;
        var x2 = x + (width || 0);
        var y2 = y + (height || 0);
        if (x < acc.x) {
            acc.x = x;
        }
        if (y < acc.y) {
            acc.y = y;
        }
        if (x2 > acc.x2) {
            acc.x2 = x2;
        }
        if (y2 > acc.y2) {
            acc.y2 = y2;
        }
        return acc;
    }, { x: Infinity, y: Infinity, x2: -Infinity, y2: -Infinity });
    var width = rect.x2 - rect.x;
    var height = rect.y2 - rect.y;
    return { x: rect.x, y: rect.y, width: width, height: height };
}
exports.getSelectionRect = getSelectionRect;
function getRelativeCoordinate(rectXY, rectWidthOrHeight, nodeXY) {
    return Math.abs(nodeXY - rectXY) / rectWidthOrHeight;
}
exports.getRelativeCoordinate = getRelativeCoordinate;
function getSelectionCoordinate(rectXY, rectWidthOrHeight, relativeXY) {
    if (relativeXY <= 0) {
        return rectXY;
    }
    return rectXY + rectWidthOrHeight * relativeXY;
}
exports.getSelectionCoordinate = getSelectionCoordinate;
function getSelectionWidthOrHeight(rectWidthOrHeight, relativeWidthOrHeight) {
    return rectWidthOrHeight * relativeWidthOrHeight;
}
exports.getSelectionWidthOrHeight = getSelectionWidthOrHeight;
