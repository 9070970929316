"use strict";
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/display-name */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var material_1 = require("@mui/material");
var redux_1 = require("@hooks/redux");
var reactflow_1 = require("reactflow");
var workspaceActions_websocket_1 = require("@pages/Workspaces/reducers/WebSocket/workspaceActions.websocket");
var CreateAddStructur = function (prop) {
    var children = prop.children, content = prop.content;
    var dispatch = (0, redux_1.useAppDispatch)();
    // const user = useAuth0().user as User;
    var rfInstance = (0, reactflow_1.useReactFlow)();
    var workspaceTab = (0, redux_1.useAppSelector)(function (state) { return state.workspace.workspaceTab; });
    var currentStep = (0, redux_1.useAppSelector)(function (state) { return state.workspaceStep.currentStep; });
    var _a = (0, react_1.useState)(false), isLoading = _a[0], SetIsLoading = _a[1];
    var onClose = function () {
        SetIsLoading(false);
    };
    var handleClick = function () {
        SetIsLoading(true);
        dispatch((0, workspaceActions_websocket_1.uploadAiPrompt)(workspaceTab.value, currentStep === null || currentStep === void 0 ? void 0 : currentStep.id, content, rfInstance, onClose));
    };
    return (react_1.default.createElement("div", null, isLoading ? (react_1.default.createElement(material_1.CircularProgress, { size: 20 })) : (react_1.default.createElement(material_1.Button, { onClick: function () { return handleClick(); }, size: "small", variant: "contained", style: { margin: "4px" } }, children))));
};
exports.default = CreateAddStructur;
