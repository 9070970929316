"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.angleToHandlePosition = exports.getToolbarPosition = exports.closestPoint = exports.getRotation = exports.getLabelOffset = exports.bearingToCompassDirection = exports.getAbsoluteKnots = exports.getRelativeKnots = exports.alterKnotsIfMultiSelectMove = exports.getDomPath = exports.roundBearing = exports.projectPoint = exports.calculateBearing = exports.calcMidPoint = exports.calcPointFromStringPath = exports.getPointTAlongPath = exports.getPointFromT = exports.getEucledianDistance = exports.LABEL_DETACH_DISTANCE = void 0;
/* eslint-disable no-sequences */
/* eslint-disable no-cond-assign */
var reactflow_1 = require("reactflow");
exports.LABEL_DETACH_DISTANCE = 10;
var DEG = 180 / Math.PI;
var DEFAULT_LABEL_HEIGHT = 20;
var getEucledianDistance = function (point1, point2) {
    var dx = point1.x - point2.x;
    var dy = point1.y - point2.y;
    return Math.sqrt(dx * dx + dy * dy);
};
exports.getEucledianDistance = getEucledianDistance;
var getPointFromT = function (path, t) {
    var totalLength = path.getTotalLength();
    var lengthFromStart = t * totalLength;
    var point = path.getPointAtLength(lengthFromStart);
    return { x: point.x, y: point.y };
};
exports.getPointFromT = getPointFromT;
var getPointTAlongPath = function (path, point, tolerance) {
    if (tolerance === void 0) { tolerance = 0.01; }
    var lower = 0;
    var pathLength = path.getTotalLength();
    var upper = path.getTotalLength();
    var closestT = (upper + lower) / 2;
    while (upper - lower > tolerance) {
        var midpoint = (upper + lower) / 2;
        var start = path.getPointAtLength(lower);
        var middle = path.getPointAtLength(midpoint);
        var end = path.getPointAtLength(upper);
        var d1 = (0, exports.getEucledianDistance)(start, point);
        var d2 = (0, exports.getEucledianDistance)(middle, point);
        var d3 = (0, exports.getEucledianDistance)(end, point);
        // Determine which segment to narrow down to
        if (d1 < d2 && d1 < d3) {
            upper = midpoint;
        }
        else if (d2 < d1 && d2 < d3) {
            lower += (midpoint - lower) / 2;
            upper -= (upper - midpoint) / 2;
        }
        else {
            lower = midpoint;
        }
        closestT = (upper + lower) / 2;
    }
    return closestT / pathLength;
};
exports.getPointTAlongPath = getPointTAlongPath;
var calcPointFromStringPath = function (stringPath, curveScalar) {
    var path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    path.setAttribute("d", stringPath);
    var point = (0, exports.getPointFromT)(path, curveScalar);
    path.remove();
    return point;
};
exports.calcPointFromStringPath = calcPointFromStringPath;
var calcMidPoint = function (point1, point2) { return ({
    x: (point1.x + point2.x) / 2,
    y: (point1.y + point2.y) / 2,
}); };
exports.calcMidPoint = calcMidPoint;
function vectorBetweenPoints(p1, p2) {
    var x = p2.x - p1.x;
    var y = p2.y - p1.y;
    return { x: x, y: y };
}
function vectorToCompassBearing(vector) {
    var angleRadians = Math.atan2(vector.y, vector.x);
    var angleDegrees = angleRadians * (180 / Math.PI);
    // Convert the angle to a compass bearing
    var bearing = (450 - angleDegrees) % 360;
    return bearing;
}
var calculateBearing = function (point, clientPoint) {
    var vector = vectorBetweenPoints(point, clientPoint);
    // Note: Because react flow has an inverted x-axis, the compass bearing is also inverted:
    //            180°
    //             |
    //             |
    //   270° ----------- 90°
    //             |
    //             |
    //             0°
    var bearing = vectorToCompassBearing(vector);
    return bearing;
};
exports.calculateBearing = calculateBearing;
var projectPoint = function (point, angleDegrees, distance) {
    // Convert angle from degrees to radians
    var angleRadians = (angleDegrees * Math.PI) / 180;
    return {
        x: point.x + distance * Math.cos(angleRadians),
        y: point.y + distance * Math.sin(angleRadians),
    };
};
exports.projectPoint = projectPoint;
var roundBearing = function (angle, referenceAngles) {
    // Normalize angle to be within the range [0, 360)
    angle %= 360;
    if (angle < 0) {
        angle += 360;
    }
    // Find the nearest reference angle
    var nearestAngle = referenceAngles.reduce(function (prev, curr) {
        return Math.abs(curr - angle) < Math.abs(prev - angle) ? curr : prev;
    });
    return nearestAngle;
};
exports.roundBearing = roundBearing;
var getDomPath = function (id) {
    var pathParent = document.querySelector("[data-testid=rf__edge-".concat(id, "]"));
    if (!pathParent)
        return null;
    var edgePath = pathParent.firstElementChild;
    if (!edgePath)
        return null;
    var path = edgePath.getAttribute("d");
    return path;
};
exports.getDomPath = getDomPath;
var alterKnotsIfMultiSelectMove = function (isNotInitialRender, knots, source, target) {
    var isMultiSelectMove = isNotInitialRender &&
        ((knots[0].x !== source.x && knots[knots.length - 1].x !== target.x) ||
            (knots[0].y !== source.y && knots[knots.length - 1].y !== target.y));
    if (isMultiSelectMove) {
        var originalSource = knots[0];
        for (var i = 1; i < knots.length - 1; i++) {
            var relativeX = knots[i].x - originalSource.x;
            var relativeY = knots[i].y - originalSource.y;
            knots[i] = {
                x: source.x + relativeX,
                y: source.y + relativeY,
            };
        }
    }
    return isMultiSelectMove;
};
exports.alterKnotsIfMultiSelectMove = alterKnotsIfMultiSelectMove;
var getRelativeKnots = function (knots) {
    var source = knots[0];
    var relativeKnots = [];
    for (var i = 1; i < knots.length - 1; i++) {
        relativeKnots.push({
            x: knots[i].x - source.x,
            y: knots[i].y - source.y,
        });
    }
    return relativeKnots;
};
exports.getRelativeKnots = getRelativeKnots;
var getAbsoluteKnots = function (knots, source, target) {
    var absoluteKnots = [source];
    knots.forEach(function (knot) {
        absoluteKnots.push({
            x: source.x + knot.x,
            y: source.y + knot.y,
        });
    });
    absoluteKnots.push(target);
    return absoluteKnots;
};
exports.getAbsoluteKnots = getAbsoluteKnots;
var bearingToCompassDirection = function (bearing) {
    var isS = bearing >= 337.5 || bearing < 22.5;
    var isN = bearing >= 157.5 && bearing < 202.5;
    var isW = bearing >= 247.5 && bearing < 292.5;
    var isE = bearing >= 67.5 && bearing < 112.5;
    var isSE = bearing >= 22.5 && bearing < 67.5;
    var isSW = bearing >= 292.5 && bearing < 337.5;
    var isNW = bearing >= 202.5 && bearing < 247.5;
    var isNE = bearing >= 112.5 && bearing < 157.5;
    return {
        isN: isN,
        isS: isS,
        isW: isW,
        isE: isE,
        isSE: isSE,
        isSW: isSW,
        isNW: isNW,
        isNE: isNE,
    };
};
exports.bearingToCompassDirection = bearingToCompassDirection;
var getLabelOffset = function (bearing, t, sourcePosition, targetPosition) {
    // If the label is at the start or end of the path, we need to adjust the bearing, so the label is not placed behind a node
    if (bearing === null || bearing === undefined)
        return "translate(-50%, -50%)";
    if (t < 0.015 || t > 0.985) {
        switch (t < 0.015 ? sourcePosition : targetPosition) {
            case reactflow_1.Position.Top:
                if (bearing >= 270 && bearing <= 360) {
                    bearing = 270;
                }
                else if (bearing >= 0 && bearing < 90) {
                    bearing = 90;
                }
                break;
            case reactflow_1.Position.Bottom:
                if (bearing >= 180 && bearing < 270) {
                    bearing = 270;
                }
                else if (bearing >= 90 && bearing < 180) {
                    bearing = 90;
                }
                break;
            case reactflow_1.Position.Left:
                if (bearing >= 0 && bearing < 90) {
                    bearing = 0;
                }
                else if (bearing >= 90 && bearing < 180) {
                    bearing = 180;
                }
                break;
            default:
                if (bearing >= 270 && bearing <= 360) {
                    bearing = 0;
                }
                else if (bearing >= 180 && bearing < 270) {
                    bearing = 180;
                }
                break;
        }
    }
    var _a = (0, exports.bearingToCompassDirection)(bearing), isN = _a.isN, isS = _a.isS, isW = _a.isW, isE = _a.isE, isSE = _a.isSE, isSW = _a.isSW, isNW = _a.isNW;
    if (isN) {
        return "translate(-50%, calc(-100% - ".concat(exports.LABEL_DETACH_DISTANCE, "px))");
    }
    if (isS) {
        return "translate(-50%, calc(0% + ".concat(exports.LABEL_DETACH_DISTANCE, "px))");
    }
    if (isW) {
        return "translate(calc(-100% - ".concat(exports.LABEL_DETACH_DISTANCE, "px), -50%)");
    }
    if (isE) {
        return "translate(calc(0% + ".concat(exports.LABEL_DETACH_DISTANCE, "px), -50%)");
    }
    if (isNW) {
        return "translate(calc(-100% - ".concat(exports.LABEL_DETACH_DISTANCE, "px), -100%)");
    }
    if (isSW) {
        return "translate(calc(-100% - ".concat(exports.LABEL_DETACH_DISTANCE, "px), 0%)");
    }
    if (isSE) {
        return "translate(calc(0% + ".concat(exports.LABEL_DETACH_DISTANCE, "px), 0%)");
    }
    // isNE
    return "translate(calc(0% + ".concat(exports.LABEL_DETACH_DISTANCE, "px), -100%)");
};
exports.getLabelOffset = getLabelOffset;
var getRotation = function (p1, p2) {
    var dx = p2.x - p1.x;
    var dy = p2.y - p1.y;
    return Math.atan2(dy, dx);
};
exports.getRotation = getRotation;
var eucledianDistance = function (p1, p2) {
    var dx = p1.x - p2.x;
    var dy = p1.y - p2.y;
    return dx * dx + dy * dy;
};
var closestPoint = function (pathElement, point) {
    var pathLength = pathElement.getTotalLength();
    var precision = 8;
    var best = new DOMPoint();
    var bestLength = 0;
    var bestDistance = Infinity;
    for (var scan = void 0, scanLength = 0, scanDistance = void 0; scanLength <= pathLength; scanLength += precision) {
        scan = pathElement.getPointAtLength(scanLength);
        scanDistance = eucledianDistance(scan, point);
        if (scanDistance < bestDistance) {
            (best = scan), (bestLength = scanLength), (bestDistance = scanDistance);
        }
    }
    precision /= 2;
    while (precision > 0.5) {
        var before = void 0;
        var after = void 0;
        var beforeLength = void 0;
        var afterLength = void 0;
        var beforeDistance = void 0;
        var afterDistance = void 0;
        if ((beforeLength = bestLength - precision) >= 0 &&
            (beforeDistance = eucledianDistance((before = pathElement.getPointAtLength(beforeLength)), point)) < bestDistance) {
            (best = before), (bestLength = beforeLength), (bestDistance = beforeDistance);
        }
        else if ((afterLength = bestLength + precision) <= pathLength &&
            (afterDistance = eucledianDistance((after = pathElement.getPointAtLength(afterLength)), point)) < bestDistance) {
            (best = after), (bestLength = afterLength), (bestDistance = afterDistance);
        }
        else {
            precision /= 2;
        }
    }
    var len2 = bestLength + (bestLength === pathLength ? -0.1 : 0.1);
    var rotation = (0, exports.getRotation)(best, pathElement.getPointAtLength(len2));
    return {
        point: { x: best.x, y: best.y },
        rotation: rotation * DEG,
        distance: Math.sqrt(bestDistance),
    };
};
exports.closestPoint = closestPoint;
var getToolbarPosition = function (absoluteKnots, labelPos, bearing) {
    var sourceKnotX = absoluteKnots[0].x;
    var targetKnotX = absoluteKnots[absoluteKnots.length - 1].x;
    var mostTopKnotY = absoluteKnots[0].y;
    absoluteKnots.forEach(function (knot) {
        if (knot.y < mostTopKnotY) {
            mostTopKnotY = knot.y;
        }
    });
    if (labelPos && labelPos.y < mostTopKnotY) {
        mostTopKnotY = labelPos.y;
    }
    if (bearing !== null && labelPos) {
        var _a = (0, exports.bearingToCompassDirection)(bearing), isN = _a.isN, isNW = _a.isNW, isNE = _a.isNE;
        var mostTopKnotYCandidate = isN
            ? labelPos.y - DEFAULT_LABEL_HEIGHT - exports.LABEL_DETACH_DISTANCE
            : isNW || isNE
                ? labelPos.y - DEFAULT_LABEL_HEIGHT
                : mostTopKnotY;
        if (mostTopKnotYCandidate < mostTopKnotY) {
            mostTopKnotY = mostTopKnotYCandidate;
        }
    }
    return {
        x: (targetKnotX + sourceKnotX) / 2,
        y: mostTopKnotY,
    };
};
exports.getToolbarPosition = getToolbarPosition;
function angleToHandlePosition() {
    /*                    .
     *               ....  .   ...
     *       Top .. .      .        .. Top
     *    Left .          .          .. Right
     *        .            .            .
     *       .            .:             :
     *       .             .              .
     *      .              .              .
     *   .  :  :  .  .  .  :  :  .  .  .. :  ..
     *      .              .              .
     *       .             .              .
     *       .            ..             :
     *        .            .            .
     *     Left :          .          .. Right
     *     Bottom ..      ..       .. Bottom
     *               .............
     *                     .
     */
    function determinePositionFromAngle(vPrime) {
        var normalized = vPrime % 360;
        if (normalized >= 45 && normalized < 135) {
            return reactflow_1.Position.Top;
        }
        if (normalized >= 135 && normalized < 225) {
            return reactflow_1.Position.Left;
        }
        if (normalized >= 225 && normalized < 315) {
            return reactflow_1.Position.Bottom;
        }
        return reactflow_1.Position.Right;
    }
    function normalizeUnitSquareToHandlePercentage(point) {
        return Math.round(50 + 50 * point);
    }
    function transformAngleToPrime(angle) {
        var angleNormalized = angle % 360;
        return (angleNormalized + 180) % 360;
    }
    function calculateCoordinates(angle, position) {
        var angleTarget = transformAngleToPrime(angle);
        var radians = (angleTarget * Math.PI) / 180;
        // Unit Circle
        var xo = +Math.cos(radians).toPrecision(8);
        var yo = +Math.sin(radians).toPrecision(8);
        var isTopBottom = position === reactflow_1.Position.Top || position === reactflow_1.Position.Bottom;
        var ratioABS = Math.abs(isTopBottom ? yo : xo) || 1;
        var xSquare = xo / ratioABS;
        var ySquare = yo / ratioABS;
        var x = normalizeUnitSquareToHandlePercentage(xSquare);
        var y = normalizeUnitSquareToHandlePercentage(ySquare);
        return { x: x, y: y };
    }
    function calculateAngleFromHandle(from, to) {
        var straightVector = [1, 0];
        var handleVector = [to.x - from.x, (to.y - from.y) * -1];
        var dotProduct = straightVector[0] * handleVector[0] + straightVector[1] * handleVector[1];
        var magnitudeVstraight = Math.sqrt(Math.pow(straightVector[0], 2) + Math.pow(straightVector[1], 2));
        var magnitudeVhandle = Math.sqrt(Math.pow(handleVector[0], 2) + Math.pow(handleVector[1], 2));
        var cosV = dotProduct / (magnitudeVstraight * magnitudeVhandle);
        var angle = Math.acos(cosV);
        var crossProduct = straightVector[0] * handleVector[1] - straightVector[1] * handleVector[0];
        if (crossProduct < 0) {
            // If cross product is negative, the angle is clockwise
            angle = 2 * Math.PI - angle;
        }
        // Convert radians to degrees
        var angleInDegrees = angle * (180 / Math.PI);
        return angleInDegrees;
    }
    /**
     * Close to the same as PowerPoint
     */
    function determinePosition(from, to) {
        var angle = transformAngleToPrime(calculateAngleFromHandle(from, to));
        return determinePositionFromAngle(angle);
    }
    function isTargetWithinClickThreshholdOfSource(source, target, threshhold) {
        if (threshhold === void 0) { threshhold = 10; }
        /*
         *                       o------
         *                        ╲    |
         *  euclidean distance →   ╲   |
         *                          ╲  |
         *                            o
         */
        var euclideanDistance = Math.sqrt(Math.pow((target.x - source.x), 2) + Math.pow((target.y - source.y), 2));
        return euclideanDistance <= threshhold;
    }
    return {
        calculateCoordinates: calculateCoordinates,
        calculateAngleFromHandle: calculateAngleFromHandle,
        determinePosition: determinePosition,
        isTargetWithinClickThreshholdOfSource: isTargetWithinClickThreshholdOfSource,
    };
}
exports.angleToHandlePosition = angleToHandlePosition;
