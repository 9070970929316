"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
exports.default = (0, mui_1.makeStyles)()(function (theme) { return ({
    container: {
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(0.25),
    },
    textField: { "&&&&": { margin: 0 } },
    toggleButton: { textTransform: "lowercase" },
    dropzone: { width: "100%", height: "100%" },
}); });
