"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
var auth0_react_1 = require("@auth0/auth0-react");
var groupNodeUtils_1 = require("@components/Workspace/Node/utils/groupNodeUtils");
var redux_1 = require("@hooks/redux");
var workspaceActions_http_1 = require("@pages/Workspaces/reducers/workspaceActions.http");
var notificationActions_1 = require("@redux/actions/notificationActions");
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
var react_router_dom_1 = require("react-router-dom");
var reactflow_1 = require("reactflow");
var useDragAndDrop = function (workspaceTab, currentStep, willSnapToGrid, toggleMouse, handlePostFile, handlePostIcon, handlePostSticky, handlePostText, handlePostGroupNode, handlePostDefaultNode) {
    var dispatch = (0, redux_1.useAppDispatch)();
    var t = (0, react_i18next_1.useTranslation)().t;
    var rfInstance = (0, reactflow_1.useReactFlow)();
    var location = (0, react_router_dom_1.useLocation)();
    var id = (0, constants_1.getId)(location);
    var user = (0, auth0_react_1.useAuth0)().user;
    var onDragOver = (0, react_1.useCallback)(function (event) {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    }, []);
    var onDrop = (0, react_1.useCallback)(function (event) {
        event.preventDefault();
        if (event.target instanceof Element) {
            var hasAncestorWithId = event.target.closest("#dropZoneOuter") !== null;
            if (hasAncestorWithId) {
                return;
            }
        }
        if (rfInstance) {
            var type = event.dataTransfer.getData("application/reactflow");
            var dropX = event.clientX;
            var dropY = event.clientY;
            var snappedX = 25 * Math.round(dropX / 25);
            var snappedY = 25 * Math.round(dropY / 25);
            var position = rfInstance.screenToFlowPosition({
                x: willSnapToGrid ? snappedX : dropX,
                y: willSnapToGrid ? snappedY : dropY,
            });
            var intersections = rfInstance
                .getIntersectingNodes({
                x: position.x,
                y: position.y,
                width: 50,
                height: 50,
            })
                .filter(function (n) { return n.type === "customgroup"; });
            var groupNode = intersections[0];
            if (event.dataTransfer &&
                event.dataTransfer.files &&
                event.dataTransfer.files.length > 0) {
                var files = event.dataTransfer.files;
                var correctFiles = Array.from(files).filter(function (f) { return f.type.includes("image"); });
                if (correctFiles.length !== files.length) {
                    dispatch((0, notificationActions_1.showNotification)("warning", t("workspace.importing_files_wrong_file")));
                }
                dispatch((0, workspaceActions_http_1.importFiles)(user, correctFiles, id, workspaceTab.value, position.x, position.y, groupNode === null || groupNode === void 0 ? void 0 : groupNode.id.split("-")[1], (currentStep === null || currentStep === void 0 ? void 0 : currentStep.id) || null));
            }
            if (type !== "customgroup" && groupNode) {
                position = (0, groupNodeUtils_1.getNodePositionInsideParent)({
                    position: position,
                    width: 50,
                    height: 50,
                }, groupNode);
            }
            if (type === "file") {
                handlePostFile(event.dataTransfer.getData("id"), position.x, position.y, groupNode === null || groupNode === void 0 ? void 0 : groupNode.id.split("-")[1]);
            }
            if (type === "icon") {
                handlePostIcon(event.dataTransfer.getData("id"), position.x, position.y, groupNode === null || groupNode === void 0 ? void 0 : groupNode.id.split("-")[1]);
            }
            if (type === "sticky") {
                handlePostSticky(event, false, position.x, position.y, groupNode === null || groupNode === void 0 ? void 0 : groupNode.id.split("-")[1]);
            }
            if (type === "text") {
                handlePostText(event, false, position.x, position.y, groupNode === null || groupNode === void 0 ? void 0 : groupNode.id.split("-")[1]);
            }
            if (type === "customgroup") {
                handlePostGroupNode(event, false, position.x, position.y);
            }
            var splittedType = type.split("-");
            if (type === "custom" || splittedType[0] === "custom") {
                handlePostDefaultNode(position.x, position.y, groupNode === null || groupNode === void 0 ? void 0 : groupNode.id.split("-")[1], splittedType[1]);
            }
        }
        toggleMouse(false);
    }, [
        rfInstance,
        willSnapToGrid,
        handlePostIcon,
        handlePostSticky,
        handlePostText,
        handlePostGroupNode,
        handlePostDefaultNode,
        toggleMouse,
        groupNodeUtils_1.getNodePositionInsideParent,
    ]);
    return { onDragOver: onDragOver, onDrop: onDrop };
};
exports.default = useDragAndDrop;
