"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var reactFlow_1 = require("@customTypes/reactFlow");
function generateTemporaryGhostNode(position, customHandle) {
    var now = Date.now();
    var tmpId = "tmp-".concat(now);
    return {
        id: tmpId,
        position: position,
        type: "ghost",
        selectable: false,
        data: {
            createdAt: "2025-01-08T11:54:10.000Z",
            isDrilldown: false,
            drilldownId: null,
            workspaceTabId: null,
            workspaceStepId: null,
            baseReferenceId: null,
            stepReferenceId: null,
            nodeId: null,
            label: null,
            id: now,
            displayName: null,
            figur: reactFlow_1.Figur.RECTANGLE,
            unitNumber: null,
            data_provider: null,
            attributes: [],
            backgroundColor: "rgba(255, 255, 255, 1)",
            borderColor: "rgba(0, 0, 0, 1)",
            labelColor: "rgba(0, 0, 0, 1)",
            fontSize: 10,
            borderStyle: "solid",
            attributeFontSize: 6,
            fontFamily: "Open Sans",
            isBold: false,
            isItalic: false,
            isUnderline: false,
            width: 50,
            height: 50,
            fitSizeToLabel: false,
            exitLevelPWC: false,
            notes: "",
            flag: {
                id: null,
                size: "m",
                horizontalOffset: 0,
                verticalOffset: 0,
                flagChanged: false,
            },
            icon: {
                item: {
                    id: null,
                    preview: "",
                },
                vector: null,
                size: "s",
                horizontalOffset: 0,
                verticalOffset: 0,
            },
            documents: [],
            handles: [
                {
                    id: now,
                    x: customHandle.x,
                    y: customHandle.y,
                    position: customHandle.position,
                    nodeId: now,
                    createdAt: "2025-01-08T11:54:10.000Z",
                    updatedAt: "2025-01-08T11:54:10.000Z",
                },
            ],
        },
        style: {
            zIndex: 2,
        },
        width: 50,
        height: 50,
    };
}
exports.default = generateTemporaryGhostNode;
