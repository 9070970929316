"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatePPTXNode = exports.PPTX_VIEW = exports.PPTX_VIEW_NODE_ID = void 0;
var usePowerPointFitter_1 = require("@hooks/flow/usePowerPointFitter");
exports.PPTX_VIEW_NODE_ID = "powerpoint";
exports.PPTX_VIEW = {
    empty: "empty",
    standard: "standard",
    structure: "structure",
};
function CreatePPTXNode(data) {
    var _a = (0, usePowerPointFitter_1.getCustomerSlidePropertiesInPx)(data.customer), width = _a.width, height = _a.height;
    return {
        id: exports.PPTX_VIEW_NODE_ID,
        type: "powerpoint",
        selectable: false,
        draggable: false,
        connectable: false,
        deletable: false,
        focusable: false,
        style: { pointerEvents: "none" },
        data: __assign({ width: width, height: height }, data),
        position: { x: 0, y: 0 },
        width: width,
        height: height,
    };
}
exports.CreatePPTXNode = CreatePPTXNode;
