"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
function getInitialChipFromCustomer(customer) {
    var _a;
    if (!customer)
        return null;
    return ((_a = {},
        _a[constants_1.CUSTOMER.HAAVIND] = "cases",
        _a[constants_1.CUSTOMER.MOALEM] = "mywork",
        _a)[customer] || null);
}
exports.default = getInitialChipFromCustomer;
