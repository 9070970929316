"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
exports.default = (0, mui_1.makeStyles)()(function () { return ({
    container: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: 1000,
        pointerEvents: "none",
    },
    innerContainer: {
        position: "absolute",
        transformOrigin: "0 0",
        width: "100%",
        height: "100%",
    },
    resizer: {
        position: "absolute",
    },
    resizeControl: {
        pointerEvents: "auto",
    },
}); });
